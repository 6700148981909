var defaultFancyboxConfig = {
	maxWidth	: 800,
	maxHeight	: 600,
	padding		: 0,
	autoSize	: true,
	autoHeight	: true,
	autoWidth	: true,
	fitToView	: true,
	openMethod	: 'dropIn',
	openSpeed	: 250,
	closeMethod	: 'dropOut',
	closeSpeed	: 150,
	tpl: {
		wrap: '<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner alt"></div></div></div></div>'
	}
};

$(document).ready(function ($) {
	// Hints
	$('.hint').qtip({
		position: {
			my: 'bottom center',
			at: 'bottom center',
			adjust: {
				x: 0,
				y: -25
			}
		},
		style: {
			tip: {
				width: 15,
				height: 10
			}
		}
	});

	// Tooltips
	$('.tooltip').qtip({
		position: {
			my: 'top left',
			at: 'bottom left',
			adjust: {
				x: 5,
				y: 0
			}
		},
		style: {
			tip: {
				width: 15,
				height: 10
			}
		}
	});

	//Popup Player
	$('body').on('click', '.popup-player', function () {
		var btn = $(this);
		var setup = JSON.parse(btn.attr('data-setup'));
		setup['width'] = '100%';
		setup['aspectratio'] = '16:9';

		var config = {
			maxWidth    : 800,
			maxHeight   : 600,
			fitToView   : true,
			width       : '70%',
			height      : '70%',
			autoSize    : false,
			closeClick  : false,
			openEffect  : 'elastic',
			closeEffect : 'none',
		}
		config['tpl'] = defaultFancyboxConfig['tpl'];
		if (btn.attr('data-max-width')) {
			config['maxWidth'] = btn.attr('data-max-width');
		}
		if (btn.attr('data-max-height')) {
			config['maxHeight'] = btn.attr('data-max-height');
		}
		if (btn.attr('data-width')) {
			config['width'] = btn.attr('data-width');
		}
		if (btn.attr('data-height')) {
			config['height'] = btn.attr('data-height');
		}
		$.fancybox.open('<div id="popup-video-content"></div>', config);

		var player = jwplayer("popup-video-content").setup(setup);
		player.on('ready', function() {
			var begin = 0;
			if (btn.attr('data-begin')) {
				var begin = parseInt(btn.attr('data-begin'), 10);
				player.seek(begin);
			}
			if (btn.attr('data-end')) {
				var end = parseInt(btn.attr('data-end'), 10);
				if (!begin || begin < end) {
					var paused = false;
					player.on('time', function (e) {
						if (!paused && e.position >= end) {
							player.pause();
							paused = true;
						}
					});
				}
			}
		});

		if (btn.attr('data-ping-play-url')) {
			$.ajax({
				url: btn.attr('data-ping-play-url'),
				cache: false
			});
		}
	});

	// init close flash messages
	initCloseFM();
});

// Fancybox
if ($.fn.fancybox) {

	// Standard fancybox
	$('.fancybox').fancybox();
	$('.photogallery-detail a').fancybox();

	// Video fancybox (works with standard url)
	//$('a[href^="https://www.youtube.com"]').click(function() {
	$('body').delegate('.ytv','click',function() {

		var video_id = $(this).attr('href').split('v=')[1];
		var ampPos = video_id.indexOf('&');
		if(ampPos != -1) {
		  	video_id = video_id.substring(0, ampPos);
		}

		$.fancybox.open([
		    {
		        href : 'https://www.youtube.com/embed/'+video_id+'?autoplay=1',
		        title : ''
		    }
		], {
		   	type		: 'iframe',
		   	maxWidth    : 800,
            maxHeight   : 600,
            fitToView   : true,
            width       : '70%',
            height      : '70%',
            autoSize    : false,
            closeClick  : false,
            openEffect  : 'elastic',
            closeEffect : 'none',
            tpl: {
				wrap: '<div class="fancybox-wrap fancybox-yt" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>'
			}
		});
		return false;
	});

	// Custom animations
	(function ($, F) {
		// Opening animation - fly from the top
		F.transitions.dropIn = function () {
			var endPos = F._getPosition(true);

			endPos.top = (parseInt(endPos.top, 10) - 500) + 'px';
			endPos.opacity = 0;

			F.wrap.css(endPos).show().animate({
				top: '+=500px',
				opacity: 1
			}, {
				duration: F.current.openSpeed,
				complete: F._afterZoomIn
			});
		};

		// Closing animation - fly to the top
		F.transitions.dropOut = function () {
			F.wrap.removeClass('fancybox-opened').animate({
				top: '-=500px',
				opacity: 0
			}, {
				duration: F.current.closeSpeed,
				complete: F._afterZoomOut
			});
		};

		// Next gallery item - fly from left side to the center
		F.transitions.slideIn = function () {
			var endPos = F._getPosition(true);

			endPos.left = (parseInt(endPos.left, 10) - 200) + 'px';
			endPos.opacity = 0;

			F.wrap.css(endPos).show().animate({
				left: '+=200px',
				opacity: 1
			}, {
				duration: F.current.nextSpeed,
				complete: F._afterZoomIn
			});
		};

		// Current gallery item - fly from center to the right
		F.transitions.slideOut = function () {
			F.wrap.removeClass('fancybox-opened').animate({
				left: '+=200px',
				opacity: 0
			}, {
				duration: F.current.prevSpeed,
				complete: function () {
					$(this).trigger('onReset').remove();
				}
			});
		};

	}(jQuery, jQuery.fancybox));

	// Modal box
	$('.modal-trigger').fancybox(defaultFancyboxConfig);

	// Configurable hijack popups
	$('a.configurable-hijack-trigger').each(function () {
		var active = $(this).attr('data-active');
		var hijackName = $(this).attr('data-hijack-name');
		var lastModification = $(this).attr('data-last-modification');
		var expireDays = parseInt($(this).attr('data-expire-days'));
		var delay = parseInt($(this).attr('data-delay')) * 1000;
		var expireSteps = parseInt($(this).attr('data-expire-steps'));
		var cookie = hijackName + lastModification;
		var selector = this;
		var blockCookieContent = parseInt($.cookie(cookie));
		var setBlockCookie = function (content) {
			if (expireDays > 0) {
				$.cookie(cookie, content, {path: '/', expires: expireDays});
			} else {
				$.cookie(cookie, content);
			}
		};

		if (active && expireSteps) {
			if (blockCookieContent > expireSteps) {
				blockCookieContent = 0;
			} else if (blockCookieContent) {
				blockCookieContent += 1;
			}
			setBlockCookie(blockCookieContent);
		}

		if ((active && !blockCookieContent) || window.location.search.indexOf(hijackName) >= 0) {
			setTimeout(function () {
				var fancyboxConfig = {};
				jQuery.extend(true, fancyboxConfig, defaultFancyboxConfig, {
					beforeClose: function () {
						setBlockCookie(1);
					}
				});
				$(selector).fancybox(fancyboxConfig).trigger('click');
			}, delay);
		}

		$('.fancybox-overlay, .modal-box a').on('click', function () {
			setBlockCookie(1);
			$.fancybox.close();
		});
	});
}

// Close Flash message
function initCloseFM() {
	$('body').delegate('.flash-message .close', 'click', function () {
		$(this).closest('.flash-message').fadeOut();
		return false;
	});

	if ($('.flash-message').length) {
		setTimeout(function () {
			$('.flash-message' + '[data-hide="fade"]').fadeOut();
			$('.flash-message' + '[data-hide="slide"]').animate({'top': '-100'}, 200, function () {
				$('.flash-message' + '[data-hide="slide"]').hide();
			});
		}, 7000);
	}
}
